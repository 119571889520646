import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import styles from './estimate-page.module.css';
import { Select } from 'antd';
import { ERoles } from '../../enums/roles';
import { useNavigate } from 'react-router-dom';
import { EstimatesTable } from '../../components/EstimatesTable/EstimatesTable';
import { useLink } from '../../hooks/useLink';
import { PrimaryButton } from '../../components/Button/PrimaryButton';
export const EstimatesPage = () => {
  const me = useSelector((state: RootState) => state.me.data);
  const navigate = useNavigate();
  const { groupedEstimates, getGroupedEstimates, verticals, getVerticals } =
    useLink();

  useEffect(() => {
    const fetchData = async () => {
      console.log(me);
      if (me?.role.role !== ERoles.MANAGER) {
        await getGroupedEstimates();
        await getVerticals();
      }

      if (me?.role.role === ERoles.MANAGER) {
        navigate('/admin/polls');
      }
    };
    if (me) {
      fetchData();
    }
  }, [me]);

  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageHeader}>Пройденные опросы</h1>
      <div className={styles.buttonContainer}>
        <PrimaryButton
          onClick={() => {
            console.log('upload');
          }}
          text={'Выгрузить'}
        />
        <Select
          showSearch
          allowClear={true}
          className={'select'}
          placeholder={'Вертикаль'}
          style={{ width: '250px', marginLeft: '100px' }}
          onChange={async (v) => {
            await getGroupedEstimates(v);
          }}
          options={verticals?.map((vertical) => {
            return {
              value: vertical.id,
              label: vertical.title,
            };
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').includes(input)
          }
        />
      </div>
      <EstimatesTable estimates={groupedEstimates} />
    </div>
  );
};
